'use strict'

###*
 # @ngdoc service
 # @name mundoData.factory:StatusData

 # @description

###
angular
  .module 'mundoData'
  .factory 'StatusData', [
    'Restangular'
    (Restangular) ->
      StatusDataBase = Restangular
        .service('statuses')

      StatusDataBase
  ]
